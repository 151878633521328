import React from 'react';


function Header() {
    return (
        <header>

        </header>
    );
}

export default Header;
import React from "react";
import "../styles/Contact.css";

export default function Contact() {


  return (
    <div className="main">
      <h1>Contact</h1>
      <p>aandrebess@gmail.com</p>
    </div>
  );
}
